import React, { FC } from 'react';
import { Footer } from '../Footer';
import { MainHeader } from '../MainHeader';
import parse from 'html-react-parser';
import { Main } from '@src/theme/Global.styled';
import { Content } from './Layout.styled';
import { Loader } from '../Loader';
import { MobileMenu } from '../Menu/MobileMenu';
import { useDesktop } from '@src/hooks/useDevice';
import { Menu } from '../Menu';
import LayoutConfig from '../../models/LayoutConfig.model';
import { IPageBlocks } from '@src/models/IPageBlocks';
import Head from 'next/head';
import { Hero } from '../Hero';
import { useRouter } from 'next/router';
import { AcceptCookiesBanner } from '@src/components/AcceptCookiesBanner';
import StagingRefresh from '../StagingReFresh';
import get from 'lodash/get';

type LayoutProps = {
  layoutConfig?: LayoutConfig;
  blockConfig?: IPageBlocks;
  timeBuild?: number;
};

export const Layout: FC<LayoutProps> = ({
  children,
  layoutConfig,
  blockConfig,
  timeBuild,
}) => {
  const router = useRouter();
  const isLargeDevice = useDesktop();
  const pageFields = blockConfig?.fields;
  const logoSrc = get(layoutConfig, 'fields.logo.fields.umbracoFile', '');

  if (!layoutConfig) return null;

  const configFields = layoutConfig.fields;

  return (
    <div className={pageFields?.customCSS}>
      <Head>
        <title>{pageFields?.pageTitle}</title>
        <meta name="keywords" content={pageFields?.metaKeywords} />
        <meta name="description" content={pageFields?.metaDescription} />
        {configFields.enableGoogleAnalytics && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-J7CXY11713"
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
          window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-J7CXY11713');
          `,
              }}
            />
          </>
        )}
      </Head>
      {pageFields?.backgroundImage && (
        <Hero
          type="fixed"
          imgSrc={pageFields?.backgroundImage}
          title={parse(configFields.topText)}
        />
      )}
      {isLargeDevice ? (
        <Menu
          logoSrc={logoSrc}
          enableLogo={pageFields?.enableLogo}
          hamburgerMenus={configFields.hamburgerMenus}
        />
      ) : (
        <MobileMenu
          logoSrc={logoSrc}
          enableLogo={pageFields?.enableLogo}
          hamburgerMenus={configFields.hamburgerMenus}
          headerTopContactLink={configFields.headerTopContactLink}          
          headerTopSearchLink={configFields.headerTopSearchLink}
        />
      )}

      <Main isHomePage={router.asPath === '/'}>
        <MainHeader
          tagLineContent={pageFields?.enableTopText ? configFields.topText : ''}
          headerTopContactLink={configFields.headerTopContactLink}          
          headerTopSearchLink={configFields.headerTopSearchLink}
        />

        <Content>
          <Loader>{children}</Loader>
        </Content>

        <Footer
          footerBottomText={configFields.footerBottomText}
          footerBottomBottomLinks={configFields.footerBottomBottomLinks}
          linkedinLink={configFields.linkedinLink}
        />
      </Main>
      {configFields.cookieBannerText && (
        <AcceptCookiesBanner cookieBannerText={configFields.cookieBannerText} />
      )}
      <StagingRefresh timeBuild={timeBuild} />
    </div>
  );
};
